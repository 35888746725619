import React, { useState, useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { jsPDF } from "jspdf";



function Sidebar({ onSelect, onLogout }) {
  return (
    <div className="Sidebar">
      <button onClick={() => onSelect('home')}>Start</button>
      <button onClick={() => onSelect('checkPerson')}>Sprawdź Osobę</button>
      <button onClick={onLogout}>Wyloguj</button> {/* Add this line */}
    </div>
  );
}


function Content({ children }) {
  return <div className="Content">{children}</div>;
}

function PersonForm({ onSubmit, isFormBeingProcessed }) {
  const [formData, setFormData] = useState({
    fullName: '',
    birthDate: '',
    address: '',
    birthPlace: '',
    idNumber: '',
    pesel: '',
    nip: '',
  });

  const handleChange = (e) => {
    if (e.target.name === 'fullName') {
      setFormData({ ...formData, fullName: e.target.value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); 
    console.log('Form is being submitted with data:', formData);
    onSubmit(formData);
  };

  return (
    <form onSubmit={handleSubmit} className='PersonForm'>
      <label>
        <span>Imię i Nazwisko:</span>
        <input type="text" name="fullName" value={formData.fullName} onChange={handleChange} required />
      </label>
      <label>
        <span>Data urodzenia:</span>
        <input type="text" name="birthDate" value={formData.birthDate} readOnly />
      </label>
      <label>
        <span>Adres zamieszkania:</span>
        <input type="text" name="address" value={formData.address} readOnly />
      </label>
      <label>
        <span>Miejsce urodzenia:</span>
        <input type="text" name="birthPlace" value={formData.birthPlace} readOnly />
      </label>
      <label>
        <span>Numer dokumentu:</span>
        <input type="text" name="idNumber" value={formData.idNumber} readOnly />
      </label>
      <label>
        <span>PESEL:</span>
        <input type="text" name="pesel" value={formData.pesel} readOnly />
      </label>
      <label>
        <span>NIP:</span>
        <input type="text" name="nip" value={formData.nip} readOnly />
      </label>
      {!isFormBeingProcessed && <button type="submit">Submit</button>}
    </form>
  );
}


function LoadingModal({ message}) {
  const [dots, setDots] = useState('');

  useEffect(() => {
    const intervalId = setInterval(() => {
      setDots((prevDots) => (prevDots.length < 5 ? prevDots + '.' : ''));
    }, 500); // Change the dot every 500ms

    return () => clearInterval(intervalId); // Clear interval on component unmount
  }, []);

  return (
    <div className="Modal LoadingModal">
      <span>Sprawdzam osobę - proszę czekaj</span><span className="dots">{dots}</span>
      <div> Sprawdzenie listy podmiotów objętych sankcjami</div>
      <div> Sprawdzanie listy ostrzeżeń publicznych</div>
      <div> Sprawdzenie rejstru działalności w zakresie walut wirtualnych </div>
      <div> Sprawdzenie listy sankcyjne ONZ</div>
      
      <div className="ProgressMessage">
        {message.map((message, index) => (
         <div key={index} className="ProgressMessage">{message}</div> 
        ))}
        </div> {/* Display progress message here */}
    </div>
  );
}


function ConfirmationModal({ message, checkedPerson, userName, onGoBack }) {
  const currentDate = new Date().toLocaleString(); // Get current date and time

  const processMessage = (message) => {
    const lines = message.split("\\n");
    return lines.map((line, index) => {
      const parts = line.split(/(!.*?)(?=\\n|$)/);
      return (
        <p key={index} style={{ color: 'green' }}>
          {parts.map((part, partIndex) =>
            part.startsWith('!') ? (
              <span key={partIndex} style={{ color: 'red' }}>{part}</span>
            ) : (
              part
            )
          )}
        </p>
      );
    });
  };
  const replacePolishChars = (text) => {
    const polishCharMap = {
      'ą': 'a', 'ć': 'c', 'ę': 'e', 'ł': 'l', 'ń': 'n',
      'ó': 'o', 'ś': 's', 'ż': 'z', 'ź': 'z',
      'Ą': 'A', 'Ć': 'C', 'Ę': 'E', 'Ł': 'L', 'Ń': 'N',
      'Ó': 'O', 'Ś': 'S', 'Ż': 'Z', 'Ź': 'Z'
    };
    return text.replace(/[ąćęłńóśżźĄĆĘŁŃÓŚŻŹ]/g, match => polishCharMap[match]);
  };

  const generatePDF = () => {
    const doc = new jsPDF();
    doc.setFontSize(10); // Set a smaller font size

    let currentLinePosition = 10; // Initial Y position for the text

    const addLineToPDF = (text, yOffset = 10, isBold = false) => {
      text = replacePolishChars(text); // Replace Polish characters
      doc.setFont(isBold ? "helvetica" : "helvetica", isBold ? "bold" : "normal"); // Set font to bold if isBold is true
      doc.text(text, 10, currentLinePosition);
      currentLinePosition += yOffset; // Increase Y position for each new line
    };

    // Adding the checked person's name in bold
    addLineToPDF(`Sprawdzana osoba: ${checkedPerson}`, 10, true);

    // Adding other details in normal font
    addLineToPDF(`Osoba ta została sprawdzona dnia: ${new Date().toLocaleString()}`);
    addLineToPDF(`Przez: ${userName}`);

    // Process and add each line of the message
    const lines = message.split("\\n"); // Split message into lines
    lines.forEach(line => {
      const isBold = line.startsWith("!"); // Check if the line starts with "!"
      addLineToPDF(line, 7, isBold); // Add each line to the PDF, bold if it starts with "!"
    });

    // Save the PDF with the checked person's name as the filename
    const fileName = `${replacePolishChars(checkedPerson).replace(/\s+/g, '_')}.pdf`;
    doc.save(fileName);
    onGoBack();
  };

  
  return (
    <div className="Modal">
      <p>Sprawdzana osoba: {checkedPerson}</p> {/* Display checked person's name */}
      <p>Osoba ta została sprawdzona dnia: {currentDate}</p> {/* Display current date and time */}
      <p>Przez: {userName}</p> {/* Display user's name */}
      {processMessage(message)}
      <button onClick={generatePDF}>Zapisz do pliku PDF</button>
      <button onClick={onGoBack}>Powrót do Menu</button>
    </div>
  );
}

function LoginForm({ onLogin, loginError }) {
  const [credentials, setCredentials] = useState({
    username: '',
    password: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCredentials({ ...credentials, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    onLogin(credentials);
  };

  return (
    <div className="LoginFormContainer">
      <div className="clientCheckText">
        <div className="line1">SPRAWDŹ KLIENTA</div>
        <div className="line2">Narzędzie do szybkiej weryfikacji klienta</div>
      </div>
      <form onSubmit={handleSubmit} className='LoginForm'>
        <label>
          <span>Username:</span>
          <input 
            type="text" 
            name="username" 
            value={credentials.username} 
            onChange={handleChange} 
            required 
          />
        </label>
        <label>
          <span>Password:</span>
          <input 
            type="password" 
            name="password" 
            value={credentials.password} 
            onChange={handleChange} 
            required 
          />
        </label>
        <button type="submit">Login</button>
        {loginError && <div className="loginError">Nieprawidłowy login</div>}
      </form>
      <div className="CenteredText">
        <h1>CLIENT CHECK</h1>
        <p>Copyright by Clientcheck Sp. z o.o.</p>
      </div>
    </div>
  );
}






  
  
  function App() {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [page, setPage] = useState('home');
    const [isLoading, setIsLoading] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [responseMessage, setResponseMessage] = useState('');
    const [ProgressMessages, setProgressMessages] = useState([]); // Add this line for progress tracking
    const [formData, setFormData] = useState({ fullName: '' }); // Holds form data, including the checked person's name
    const [loggedInUser, setLoggedInUser] = useState({ username: '' }); // Holds logged-in user's information
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [isFormBeingProcessed, setIsFormBeingProcessed] = useState(false);
    const [loginError, setLoginError] = useState(false);

    
    const handleLogin = async (credentials) => {
      try {
        const response = await fetch('https://bknd.clientcheck.name:443/login', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          //credentials: 'include', // if you're using session cookies
          body: JSON.stringify({ credentials})
        });

       // .then(response => response.json())
       // .then(data => console.log(data))
       // .catch(error => console.error('Error:', error));

    
        const data = await response.json();
        console.log("wydruk testowy")
    
        if (response.ok) {
          setIsLoggedIn(true);
          setLoggedInUser({ username: credentials.username }); // Update loggedInUser with the username
          setLoginError(false);
          console.log(data.message); // Logged in successfully
          // You might want to store the logged-in state in local storage or context
        } else {
          setIsLoggedIn(false);
          setLoginError(true);
          console.error(data.message); // Invalid username or password
          // Handle login failure (e.g., show an error message)
        }
      } catch (error) {
        console.error('Network error:', error);
        setLoginError(true);
        // Handle network errors (e.g., show an error message)
      }
    };
    
    const handleSelect = (selectedPage) => {
      setPage(selectedPage);
      setShowConfirmation(false);
    };
    
    const pollProgress = () => {
      const intervalId = setInterval(() => {
        fetch('https://bknd.clientcheck.name:443/check-progress')
          .then(response => response.json())
          .then(data => {
            if (data.done) {
          // Check if the done flag is true
          setIsLoading(false);
          clearInterval(intervalId); // Stop polling
        } else if (data.progress) {
          setProgressMessages(prevMessages => {
            const lastMessage = prevMessages[prevMessages.length - 1];
            if (lastMessage !== data.progress) {
              return [...prevMessages, data.progress];
            }
            return prevMessages;
              });
            } else {
              clearInterval(intervalId); // Stop polling when there's no progress to report
            }
          })
          .catch(error => {
            console.error('Error:', error);
            clearInterval(intervalId); // Stop polling on error
          });
      }, 1000); // Poll every 2 seconds
    };

    const handleSubmit = async (submittedFormData) => {
      console.log('Submitting form data:', submittedFormData);
      setIsFormBeingProcessed(true); // Indicate that form processing has started
      setFormData(submittedFormData)
      setIsLoading(true); // Start loading
      setProgressMessages([])
      pollProgress();
      try {
        const response = await fetch('https://bknd.clientcheck.name:443/check-person', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(submittedFormData),
        });
  
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
  
        const data = await response.json();
        console.log('Response from server:', data);
        setResponseMessage(data.message);
        setShowConfirmation(true);
      } catch (error) {
        console.error('Error submitting form:', error);
        setResponseMessage('An error occurred while checking the person.');
      } finally {
        setIsLoading(false); // Stop loading regardless of the outcome
      }
      setIsFormSubmitted(true)
    };
  
    const handleGoBack = () => {
      setPage('home');
      setShowConfirmation(false);
      setIsFormBeingProcessed(false);
    };
  
    const handleLogout = async () => {
      try {
        const response = await fetch('https://bknd.clientcheck.name:443/logout', {
          method: 'POST', // Assuming GET is the method for logging out
          // If you have credentials or headers, add them here
          headers: {
            'Content-Type': 'application/json',
          },
        });
  
        if (response.ok) {
          setIsLoggedIn(false);
          console.log('Logged out successfully');
          // Redirect to login page or reset the state as needed
        } else {
          console.error('Logout failed');
          // Handle logout failure
        }
      } catch (error) {
        console.error('Network error:', error);
        // Handle network errors
      }
    };


    return (
      <Router>
        <div className="App">
          <Routes>
            <Route path="/login" element={isLoggedIn ? <Navigate to="/" replace /> : <LoginForm onLogin={handleLogin} loginError={loginError} />} />
            <Route path="/" element={isLoggedIn ? (
              <>
                <Sidebar onSelect={handleSelect} onLogout={handleLogout}/>
                <Content>
                  {page === 'home' && (
                    <div>
                      <span className="uwagaText">Uwaga!</span>
                      <span className="messageText">Zalogowanie tego samego uzytkownika na kilku stanowiskach jednocześnie moźe spowodować nieprawidłowe działanie aplikacji !!!</span>
                    </div>        
                  )}
                  {page === 'checkPerson' && <PersonForm onSubmit={handleSubmit} isFormBeingProcessed={isFormBeingProcessed} />}
                  {isLoading && <LoadingModal  message={ProgressMessages}/>}
                  {showConfirmation && <ConfirmationModal 
                    message={responseMessage}
                    checkedPerson={formData.fullName}
                    userName={loggedInUser.username}
                    onGoBack={handleGoBack} />}
                  {/*<Sidebar onSelect={handleSelect} onLogout={handleLogout} /> */}
                  {/* Insert other components or content based on the page state */}
                </Content>
              </>
            ) : (
              <Navigate to="/login" replace />
            )} />
            {/* Define other routes here if needed */}
          </Routes>
        </div>
      </Router>
    );
  }
  
export default App;
